import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e78d2e06"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "XyzYhrzList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("信用证号：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.xyzCode = _ctx.valid(e)),
                  placeholder: "请输入信用证号",
                  modelValue: _ctx.pageList.queryParam.mainId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.mainId) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("承兑及收汇日期：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.date,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.date) = $event)),
                  placeholder: "请选择承兑及收汇日期"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("是否提交：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.pageList.queryParam.flag,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.flag) = $event)),
                  placeholder: "请选择是否提交",
                  style: {"width":"100%"},
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "未提交",
                      value: "0"
                    }),
                    _createVNode(_component_el_option, {
                      label: "已提交",
                      value: "1"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_XYZ_CODE",
          label: "信用证号"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              onClick: ($event: any) => (_ctx.openXyzCard(scope.row.F_MAIN_ID)),
              style: {"cursor":"pointer"},
              title: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.F_XYZ_CODE), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_DATE",
          label: "承兑及收汇日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: "实际收汇金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SXF",
          label: "手续费",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_SXF,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FLAG",
          label: "是否提交"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_USER_NAME",
          label: "添加人"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}